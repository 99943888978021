import React, { ReactNode } from 'react';
import Dashboard from './Components/Dashboard';
import { Footer } from './Components';

export default class App extends React.Component {
    public render(): ReactNode {
        return (
            <>
                <Dashboard />
                <Footer />
            </>
        );
    }
}
