import React, { ReactNode } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Config from "../../Config";
import IPumpData from "../../interfaces/IPumpData";
import IPumpPlotProps from "../../interfaces/IPumpPlotProps";

interface IPumpPlotState {
    data: IPumpData[];
}

// Using demo as described here
// https://www.amcharts.com/docs/v4/getting-started/integrations/using-react/
export abstract class PlotBase<TPlotModel> extends React.Component<IPumpPlotProps, IPumpPlotState> {

    private static readonly ReloadTimeout: number = 20000;

    protected readonly key: string;
    protected chart: am4charts.XYChart | null;
    private intervalId: number;

    constructor(props: IPumpPlotProps, type: string) {
        super(props);

        this.key = `${type}-${props.device}`.toLowerCase();
        this.intervalId = 0;
        this.chart = null;
        this.state = {
            data: new Array<IPumpData>()
        };

    }

    protected abstract createChart(): void;

    protected abstract mapData(value: IPumpData): TPlotModel;

    protected abstract axisHook(x: am4charts.Axis<am4charts.AxisRendererY>, isMobile: boolean, data: Array<IPumpData>): void;

    componentDidMount(): void {
        this.createChart();
        this.fetchData();

        // This is not run from NodeJs, so use window function
        this.intervalId = window.setInterval(this.fetchData.bind(this), PlotBase.ReloadTimeout);
    }

    componentWillUnmount(): void {

        // Stop reloading
        window.clearInterval(this.intervalId);

        if (this.chart) {
            this.chart.dispose();
        }
    }

    public componentDidUpdate(prevProps: IPumpPlotProps): void {
        const { startDate, endDate } = this.props;
        if (prevProps.startDate !== startDate || prevProps.endDate !== endDate) {

            // Pause automatic reload
            window.clearInterval(this.intervalId);

            if (startDate && endDate) {
                this.fetchByDateRange();
            } else {
                this.fetchByLatest();
            }

            // Create new scheduler for reloading all data
            this.intervalId = window.setInterval(this.fetchData.bind(this), PlotBase.ReloadTimeout);
        }
    }

    protected setTooltipColor(series: am4charts.LineSeries, color: string): void {
        if (series.tooltip) {
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = am4core.color(color);
        }
    }

    private fetchData(): void {
        if (this.props.startDate && this.props.endDate) {
            this.fetchByDateRange();
        } else {
            this.fetchByLatest();
        }
    }

    private fetchItemData(input: RequestInfo): void {
        // https://mietpumpen.ch/api/v2/ELMA_004/items
        fetch(input)
            .then(response => response.json() as Promise<IPumpData[]>)
            .then(data => {
                this.setState({ data: data });
            });
    }

    private fetchByLatest(): void {
        const url = `${Config.ApiServerAddress}/${this.props.device}/items`;
        this.fetchItemData(url);
    }

    private fetchByDateRange(): void {
        const url = `${Config.ApiServerAddress}/${this.props.device}/between?start=${this.props.startDate}&end=${this.props.endDate}`;
        this.fetchItemData(url);
    }

    private filterData(value: IPumpData, index: number, array: IPumpData[]): boolean {
        if (index <= 0) {
            return true;
        }

        const a = value.timestamp as Date;
        const b = array[0].timestamp as Date;

        if (a !== undefined && b !== undefined) {

            // Calculate diff in days
            const deltaDays = Math.floor(((new Date(b)).getTime() - (new Date(a)).getTime()) / (1000 * 60 * 60 * 24));

            // Only show max 10 days back
            if (deltaDays > 10) {
                return false;
            }
        }

        return true;
    }

    public render(): ReactNode {

        const { isMobile } = this.props;

        if (this.chart) {

            const { data } = this.state;

            // This is only used till the data can be mapped correctly.
            // https://github.com/amcharts/amcharts4/issues/1571 for the zooming issue
            this.chart.data = data.filter(this.filterData).map(x => this.mapData(x)).reverse();
            this.chart.yAxes.values.forEach(x => this.axisHook(x, isMobile, data));
            this.chart.fontSize = isMobile ? '0.8em' : undefined;
        }

        return (
            <div id={this.key} style={{ width: "100%", height: "500px" }}></div>
        );
    }
}
