import * as React from "react";

export class Footer extends React.Component {
    public render(): React.ReactNode {
        const currentYear = new Date().getFullYear();
        return (
            <div className="footer">
                <p>© {currentYear} ensigra GmbH</p>
            </div>
        );
    }
}
