import React, { ReactNode } from "react";
import Config from "../../Config";
import Moment from "react-moment";
import IDeviceProp from "../../interfaces/IDeviceProp";
import IErrorSummary from "../../interfaces/IErrorSummary";
import ErrorHelper from "../../helpers/ErrorHelper";

interface ICurrentStatusState {
    data: IErrorSummary[];
}

export class ErrorTable extends React.Component<IDeviceProp, ICurrentStatusState> {
    constructor(props: IDeviceProp) {
        super(props);
        this.state = {
            data: []
        };
    }

    public componentDidMount(): void {
        this.fetchStatusData(this.props.device);
    }

    public componentDidUpdate(prevProps: IDeviceProp): void {
        const { device } = prevProps;
        if (device !== this.props.device) {
            this.fetchStatusData(this.props.device);
        }
    }

    private fetchStatusData(device: string): void {
        fetch(this.generateStatusAPIUrl(device))
            .then(x => x.json() as Promise<IErrorSummary[]>)
            .then(x => {
                this.setState({ data: x });
            });
    }

    private generateStatusAPIUrl(device: string): string {
        return Config.ApiServerAddress + `/${device}/errors`;
    }

    public render(): ReactNode {
        const { data } = this.state;
        if (!data || data.length === 0) {
            return <p className="text-center">Perfekt, es gab keine Fehler in den letzten 5 Tagen <span aria-label="Hey, that's cool!" role="img">😀</span>.</p>;
        }

        return (
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Zeitbereich</th>
                        <th>Fehler</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(x => {
                        const key: string = this.props.device + x.start;
                        return (
                            <tr key={key}>
                                <td><Moment date={x.start} utc local format={"DD.MM.YYYY"} /></td>
                                <td><Moment date={x.start} utc local format={"HH:mm"} /> Uhr - <Moment date={x.end} utc local format={"HH:mm"} /> Uhr</td>
                                <td>{ErrorHelper.ExplainErrorFlags(x.errorRegister).map(y => {
                                    return <span style={{ marginRight: '5px' }} key={"error_" + y.bit} className="badge badge-danger"><strong>{y.bit + 1}</strong>: {y.text}</span>
                                })}</td>
                            </tr>);
                    })}
                </tbody>
            </table>
        );
    }
}
