import React, { ReactNode } from "react";

export class Loader extends React.Component {
    public render(): ReactNode {
        return (
            <div className="loader loader-container">
                <div className="loader item">
                    <img src="/status/logo.svg" alt="ELMA Logo" title="ELMA - die beste elektrische Pumpstation die auf jeden Anhänger passt." style={{ maxWidth: 200, maxHeight: 90 }} />
                    <div className="multi-spinner-container">
                        <div className="multi-spinner">
                            <div className="multi-spinner">
                                <div className="multi-spinner">
                                    <div className="multi-spinner">
                                        <div className="multi-spinner">
                                            <div className="multi-spinner"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="text-center">Daten werden geladen ...</p>
                </div>
            </div>
        );
    }
}
