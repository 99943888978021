import IErrorFlag from "../interfaces/IErrorFlag";

export default class ErrorHelper {

    public static ErrorRegisterToFlags(errorRegister: number, offset: number): number[] {

        const errorFlags = new Array<number>();
        for (let i = 0; i < 32; i++) {
            if (errorRegister & Math.pow(2, i)) {
                errorFlags.push(i + offset);
            }
        }

        return errorFlags;
    }

    public static ExplainErrorFlags(errorRegister: number): IErrorFlag[] {

        const bits = ErrorHelper.ErrorRegisterToFlags(errorRegister, 0);
        return ErrorHelper
            .GetV3Errors()
            .filter(x => bits.indexOf(x.bit) >= 0);
    }

    private static GetV3Errors(): IErrorFlag[] {
        return [
            {
                bit: 0,
                text: 'Kein Vakuum möglich',
                sps: 'VakuumZiehtNicht'
            },
            {
                bit: 1,
                text: 'Sonde oben feucht',
                sps: 'AnsaugNiveauOben'
            },
            {
                bit: 2,
                text: 'Allgemein 11',
                sps: 'Allgemein_11'
            },
            {
                bit: 3,
                text: 'Sammelalarm FU',
                sps: 'FUSammelalarm'
            },
            {
                bit: 4,
                text: 'Maximale Neustarts erreicht',
                sps: 'MaxNeustart'
            },
            {
                bit: 5,
                text: 'Allgemein 14',
                sps: 'Allgemein_14'
            },
            {
                bit: 6,
                text: 'Allgemein 15',
                sps: 'Allgemein_15'
            },
            {
                bit: 7,
                text: 'Allgemein 16',
                sps: 'Allgemein_16'
            },
            {
                bit: 8,
                text: 'Niveaustand ist hoch',
                sps: 'NiveauHoch'
            },
            {
                bit: 9,
                text: 'Allgemein 2',
                sps: 'Allgemein_2'
            },
            {
                bit: 10,
                text: 'Allgemein 3',
                sps: 'Allgemein_3'
            },
            {
                bit: 11,
                text: 'Allgemein 4',
                sps: 'Allgemein_4'
            },
            {
                bit: 12,
                text: 'Allgemein 5',
                sps: 'Allgemein_5'
            },
            {
                bit: 13,
                text: 'Allgemein 6',
                sps: 'Allgemein_6'
            },
            {
                bit: 14,
                text: 'Allgemein 7',
                sps: 'Allgemein_7'
            },
            {
                bit: 15,
                text: 'Hochwasser oder Stromausfall',
                sps: 'Hochwasser'
            },
        ];
    }
}