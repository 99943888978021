import React, { ReactNode } from "react";
import IPumpData from "../../interfaces/IPumpData";
import Config from "../../Config";
import Moment from "react-moment";
import IDeviceProp from "../../interfaces/IDeviceProp";
import ErrorHelper from "../../helpers/ErrorHelper";

interface ICurrentStatusState {
    data?: IPumpData;
}

export class CurrentStatus extends React.Component<IDeviceProp, ICurrentStatusState> {

    constructor(props: IDeviceProp) {
        super(props);
        this.state = {};
    }

    public componentDidMount(): void {
        this.fetchStatusData(this.props.device);
    }

    public componentDidUpdate(prevProps: IDeviceProp): void {
        const { device } = prevProps;
        if (device !== this.props.device) {
            this.fetchStatusData(this.props.device);
        }
    }

    private fetchStatusData(device: string): void {
        fetch(this.generateStatusAPIUrl(device))
            .then(x => x.json() as Promise<IPumpData>)
            .then(x => {
                this.setState({ data: x });
            });
    }

    private generateStatusAPIUrl(device: string): string {
        return Config.ApiServerAddress + `/${device}/status`;
    }

    public render(): ReactNode {
        const { data } = this.state;
        if (!data) {
            return <></>;
        }

        return (
            <div className="btn-toolbar justify-content-center d-none d-sm-none d-md-flex" role="toolbar">
                <div className="btn-group btn-group-sm mr-2" role="group">
                    <button type="button" className="btn btn-warning" disabled>Strom</button>
                    <button type="button" className="btn btn-warning">{data.current < 0.05 ? 0.0 : data.current}</button>
                    <button type="button" className="btn btn-warning" disabled>A</button>
                </div>

                {data.errorRegister > 0 &&
                    <div className="btn-group btn-group-sm mr-2" role="group">
                        <button type="button" className="btn btn-danger" disabled>Fehler</button>
                        <button type="button" className="btn btn-danger">{ErrorHelper.ErrorRegisterToFlags(data.errorRegister, 1).join(', ')}</button>
                    </div>
                }

                <div className="btn-group btn-group-sm mr-2" role="group">
                    <button type="button" className="btn btn-primary" disabled>Niveau</button>
                    <button type="button" className="btn btn-primary">{data.levelCurrent} ({data.levelSetPoint})</button>
                    <button type="button" className="btn btn-primary" disabled>mm</button>
                </div>

                <div className="btn-group btn-group-sm mr-2" role="group">
                    <button type="button" className="btn btn-success" disabled>Frequenz</button>
                    <button type="button" className="btn btn-success">{data.rpm}</button>
                    <button type="button" className="btn btn-success" disabled>min<sup>-1</sup></button>
                </div>

                <div className="btn-group btn-group-sm mr-2 d-none d-lg-block" role="group">
                    <button type="button" className="btn btn-secondary" disabled>Letzte Messung</button>
                    <button type="button" className="btn btn-secondary"><Moment locale="de" date={data.timestamp} format={"LLLL"} /> Uhr</button>
                </div>
            </div>
        );
    }
}
