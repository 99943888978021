import * as React from "react";
import { TimelinePlot, CurrentStatus, ErrorTable, OverviewPlot, DetailPlot } from "./../index";
import Moment from "react-moment";
import IDeviceProp from "../../interfaces/IDeviceProp";

interface IDeviceState {
    startDate?: Date;
    endDate?: Date;
}

export class Device extends React.Component<IDeviceProp, IDeviceState> {

    public constructor(props: IDeviceProp) {
        super(props);

        this.state = {
            startDate: undefined,
            endDate: undefined,
        };
    }

    private setStartEndDate(startDate: Date, endDate: Date): void {
        this.setState({
            startDate, endDate
        });
    }

    public render(): React.ReactNode {

        const { isMobile, device } = this.props;
        const { startDate, endDate } = this.state;
        const className = isMobile ? "text-center elma-plot" : "text-center";

        return (
            <>
                <hr className="d-none d-md-block" />
                {startDate && endDate && (
                    <p className="text-center"><Moment format="LLL" date={startDate} /> - <Moment format="LT" date={endDate} /> Uhr</p>
                )
                }
                <CurrentStatus isMobile={isMobile} device={device} />
                <hr />
                <TimelinePlot isMobile={isMobile} key={device + 'timeline'} device={device} setStartEndDate={this.setStartEndDate.bind(this)} />
                <hr />

                <div className={className}>
                    <OverviewPlot
                        isMobile={isMobile}
                        startDate={startDate}
                        endDate={endDate}
                        key={device + "overview"}
                        device={device} />
                </div>
                <hr />
                <div className={className}>
                    <DetailPlot
                        isMobile={isMobile}
                        startDate={startDate}
                        endDate={endDate}
                        key={device + "detail"}
                        device={device} />
                </div>
                <div className="container d-none d-md-block">
                    <hr />
                    <div className="col-sm">
                        <ErrorTable isMobile={isMobile} device={device} />
                    </div>
                </div>
            </>
        );
    }
}