import React, { ReactNode } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Config from "../../Config";
import IOverview from "../../interfaces/IOverview";
import IDeviceProp from "../../interfaces/IDeviceProp";

interface IPlotProps extends IDeviceProp {
    setStartEndDate(startDate: Date, endDate: Date): void;
    device: string;
}

interface IHeatmapOverview extends IOverview {
    date: string;
    section: number;
}

interface IPlotState {
    data: IHeatmapOverview[];
}


export class TimelinePlot extends React.Component<IPlotProps, IPlotState> {
    private readonly key: string;
    private chart: am4charts.XYChart | null;

    constructor(props: IPlotProps) {
        super(props);

        this.key = "chartTimeline" + props.device;
        this.chart = null;

        this.state = {
            data: new Array<IHeatmapOverview>()
        };
    }

    componentDidMount(): void {
        this.fetchData();
        this.chart = this.createChart();
    }

    private createChart(): am4charts.XYChart {

        const chart: am4charts.XYChart = am4core.create(
            this.key,
            am4charts.XYChart
        );

        chart.maskBullets = false;

        const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = "date";
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.minGridDistance = 1;
        xAxis.renderer.inversed = true;

        yAxis.dataFields.category = "section";
        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.labels.template.disabled = true;
        yAxis.renderer.minGridDistance = 1;
        yAxis.minY = 0;
        yAxis.maxY = 24;

        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "date";
        series.dataFields.categoryY = "section";
        series.dataFields.value = "runningCount";

        const bgColor = new am4core.InterfaceColorSet().getFor("background");

        const columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 1;
        columnTemplate.strokeOpacity = 0.2;
        columnTemplate.stroke = bgColor;
        columnTemplate.width = am4core.percent(100);
        columnTemplate.height = am4core.percent(100);
        columnTemplate.column.adapter.add("fill", (fill, target) => {
            if (target.dataItem && target.dataItem.dataContext) {
                const item = target.dataItem.dataContext as IHeatmapOverview;
                if (item.errorCount > 0) {
                    const error = 10 * item.errorCount;
                    return am4core.color("#dc3545", error / (item.runningCount + error));
                }
            }
            return fill;
        });

        columnTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        columnTemplate.events.on('hit', (ev) => {
            const dataItem = ev.target.dataItem as am4charts.ColumnSeriesDataItem;
            if (dataItem) {
                this.handleClick(dataItem);
            }
        }, this);

        series.heatRules.push({
            target: columnTemplate,
            property: "fill",

            // When using transparent background, use bgColor here too, otherwise define a default color
            // min: am4core.color(bgColor),
            min: am4core.color('#f8f9fa'),
            max: chart.colors.getIndex(0)
        });

        return chart;
    }

    private handleClick(dataItem: am4charts.ColumnSeriesDataItem): void {
        const clickedItem = dataItem.dataContext as IHeatmapOverview;
        if (clickedItem) {
            this.props.setStartEndDate(clickedItem.startDate, clickedItem.endDate);
        }
    }

    private fetchData(): void {
        fetch(this.getDataUrl())
            .then(x => x.json() as Promise<IHeatmapOverview[]>)
            .then(x => {
                x.forEach(y => {
                    const date = new Date(y.startDate);

                    // Adding the properties for the heatmap
                    y.date = date.toLocaleDateString('de-CH');
                    y.section = date.getUTCHours();
                });

                this.setState({ data: x });
            });
    }

    private getDataUrl(): string {
        return Config.ApiServerAddress + `/${this.props.device}/overview`;
    }

    public componentWillUnmount(): void {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    public componentDidUpdate(prevProps: IPlotProps): void {
        const { device } = prevProps;
        if (device !== this.props.device) {
            this.fetchData();
        }
    }

    public render(): ReactNode {

        // Somehow updating the values does not work
        if (this.chart && this.chart.data.length <= 0) {
            this.chart.data = this.state.data;
        }

        if (this.chart) {
            this.chart.xAxes.each(x => {
                x.setVisibility(!this.props.isMobile);
            });
        }

        return (
            <div id={this.key} style={{ width: '100%' }}></div>
        );
    }
}
