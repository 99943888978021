import React, { ReactNode } from "react";
import Config from "../Config";
import IDeviceProp from "../interfaces/IDeviceProp";
import { Loader, Device } from "./index";
import { Switch, Route, BrowserRouter, NavLink, Link } from "react-router-dom";

interface IDashboardState extends IDeviceProp {
    devices: string[];
}

export default class Dashboard extends React.Component<{}, IDashboardState> {
    public constructor(props: {}) {
        super(props);

        this.state = {
            device: "",
            devices: [],
            isMobile: window.innerWidth < 767
        };
    }

    private updateDimensions(): void {
        this.setState({ isMobile: window.innerWidth < 767 });
    };

    componentDidMount(): void {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        fetch(Config.DeviceApiServerAddress)
            .then(response => response.json())
            .then(data =>
                this.setState({
                    devices: data,
                    device: data.length > 1 && data[1]
                })
            );
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    private setSelectedDevice(device: string): void {
        this.setState({
            device,
        });
    }

    public render(): ReactNode {
        const { devices, isMobile } = this.state;

        if (devices.length <= 0) {
            return <Loader />;
        }

        return (
            <BrowserRouter basename={'/status'}>
                <div className="main-container">
                    <div className="container">
                        <div className="text-center">
                            <Link to="/">
                                <img src="/status/logo.svg" alt="ELMA Logo" style={{ margin: 15, maxWidth: isMobile ? 100 : 200, maxHeight: isMobile ? 100 : 200 }} />
                            </Link>
                        </div>
                        <div className="text-center">
                            {devices.map((device, index) => {
                                return <NavLink
                                    key={index}
                                    activeClassName={"active"}
                                    className={"btn btn-light"}
                                    style={{ margin: 10 }}
                                    exact={true}
                                    to={`/${device.toLowerCase()}`}>
                                    {device}
                                </NavLink>;
                            })}
                        </div>

                        <Switch>
                            <Route path="(/status)?/:device([Ee]\d+[Kk].*)" render={(x): React.ReactNode => {
                                const device = x.match.params.device;
                                return <Device key={device} device={device} isMobile={isMobile} />;
                            }} />
                            <Route path="(/status)?/">
                                <div className="text-center">
                                    <p>Bitte wählen Sie eine Pumpe aus.</p>
                                </div>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </BrowserRouter>
        );
    }
}
