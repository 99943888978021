import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import IPumpData from "../../interfaces/IPumpData";
import { PlotBase } from "..";
import IPumpPlotProps from "../../interfaces/IPumpPlotProps";
import ErrorHelper from "../../helpers/ErrorHelper";

interface IOverviewModel {
    date: Date;
    current: number;
    errorRegister: number;
    errorValue: number;
    level: number;
    levelOn: number;
    levelOff: number;
    levelSetPoint: number;
    flow: number;
}

// Using demo as described here
// https://www.amcharts.com/docs/v4/getting-started/integrations/using-react/
export class OverviewPlot extends PlotBase<IOverviewModel> {

    constructor(props: IPumpPlotProps) {
        super(props, 'overview-plot');
    }

    protected mapData(value: IPumpData): IOverviewModel {
        return {
            date: new Date(value.timestamp),
            current: value.current > 0.05 ? value.current : 0,

            // Making the fill below almost unlimited high results in a bar chart :-)
            errorRegister: value.errorRegister,
            errorValue: value.errorRegister > 0 ? 30 : 0,
            level: value.levelCurrent / 10,
            levelOn: value.levelOn / 10,
            levelOff: value.levelOff / 10,
            levelSetPoint: value.levelSetPoint / 10,
            flow: value.flow > 0 ? value.flow / 1000 : 0
        };
    }

    protected axisHook(x: am4charts.Axis<am4charts.AxisRendererY>, isMobile: boolean, data: Array<IPumpData>): void {
        //x.renderer.labels.template.disabled = disabled;
        if (x.title.text !== 'Niveaus (cm)' && x.title.text !== 'Strom (A)') {
            x.disabled = isMobile;
        }

        if (x.title.text === 'Durchfluss (l/s)') {
            x.disabled = data.filter(x => x.flow > 0).length <= 0;
        }
    }

    protected createChart(): void {
        // declare chart
        const axisWidth = this.props.isMobile ? 0 : 35;
        const chart: am4charts.XYChart = am4core.create(
            this.key,
            am4charts.XYChart
        );

        // Adding a legend
        chart.legend = new am4charts.Legend();

        // Adding the cursor
        chart.cursor = new am4charts.XYCursor();

        // Adding default date axis
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.baseInterval = { timeUnit: "minute", count: 1 };

        // Axis (left)
        const fillLevelAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (fillLevelAxis.tooltip) {
            fillLevelAxis.tooltip.disabled = true;
        }

        fillLevelAxis.renderer.minWidth = axisWidth;
        fillLevelAxis.title.text = "Niveaus (cm)";

        // Axis (right, #2)
        const currentAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (currentAxis.tooltip) {
            currentAxis.tooltip.disabled = true;
        }

        currentAxis.renderer.minWidth = axisWidth;
        currentAxis.title.text = "Strom (A)";
        currentAxis.renderer.opposite = true;
        currentAxis.min = 0;
        currentAxis.max = 30;

        // Axis (right, #4)
        const flowAxis = chart.yAxes.push(new am4charts.ValueAxis());
        flowAxis.renderer.minWidth = axisWidth;
        flowAxis.title.text = "Durchfluss (l/s)";
        flowAxis.renderer.opposite = true;
        flowAxis.min = 0;
        flowAxis.max = 100;

        // series
        const levelSeries = chart.series.push(new am4charts.LineSeries());
        levelSeries.name = "Niveau";
        levelSeries.dataFields.dateX = "date";
        levelSeries.dataFields.valueY = "level";
        levelSeries.strokeWidth = 2;
        levelSeries.tooltipText = "Niveau: {valueY} cm";
        levelSeries.yAxis = fillLevelAxis;
        this.setTooltipColor(levelSeries, "blue");

        const levelSetPointSeries = chart.series.push(new am4charts.LineSeries());
        levelSetPointSeries.name = "Niveau Sollwert";
        levelSetPointSeries.dataFields.dateX = "date";
        levelSetPointSeries.dataFields.valueY = "levelSetPoint";
        levelSetPointSeries.strokeWidth = 1.5;
        levelSetPointSeries.stroke = am4core.color("red");
        levelSetPointSeries.strokeDasharray = "3,3"
        levelSetPointSeries.tooltipText = "Sollwert: {valueY} cm";
        levelSetPointSeries.yAxis = fillLevelAxis;
        this.setTooltipColor(levelSetPointSeries, "red");

        const currentSeries = chart.series.push(new am4charts.LineSeries());
        currentSeries.name = "Strom";
        currentSeries.dataFields.dateX = "date";
        currentSeries.dataFields.valueY = "current";
        currentSeries.stroke = am4core.color("orange");
        currentSeries.strokeWidth = 2;
        currentSeries.tooltipText = "Strom: {valueY} A";
        currentSeries.yAxis = currentAxis;
        this.setTooltipColor(currentSeries, "orange");

        const flowSeries = chart.series.push(new am4charts.LineSeries());
        flowSeries.name = "Durchfluss";
        flowSeries.dataFields.dateX = "date";
        flowSeries.dataFields.valueY = "flow";
        flowSeries.stroke = am4core.color("purple");
        flowSeries.strokeWidth = 2;
        flowSeries.tooltipText = "Durchfluss: {valueY} l/s";
        flowSeries.yAxis = flowAxis;
        this.setTooltipColor(flowSeries, "purple");

        const onSeries = chart.series.push(new am4charts.LineSeries());
        onSeries.name = "Förderbereich";
        onSeries.dataFields.dateX = "date";
        onSeries.dataFields.openValueY = "levelOff";
        onSeries.dataFields.valueY = "levelOn";
        onSeries.strokeWidth = 2;
        onSeries.strokeOpacity = 0.3;
        onSeries.tooltipText = "Einschaltniveau: {valueY} cm";
        onSeries.fillOpacity = 0.3;
        onSeries.fill = am4core.color("lightblue");
        onSeries.stroke = am4core.color("blue");
        onSeries.hiddenInLegend = true;
        this.setTooltipColor(onSeries, "lightblue");

        const offSeries = chart.series.push(new am4charts.LineSeries());
        offSeries.name = "Ausschaltniveau";
        offSeries.dataFields.dateX = "date";
        offSeries.dataFields.valueY = "levelOff";
        offSeries.strokeWidth = 2;
        offSeries.strokeOpacity = 0.3;
        offSeries.tooltipText = "Ausschaltniveau: {valueY} cm";
        offSeries.stroke = am4core.color("blue");
        offSeries.hiddenInLegend = true;
        this.setTooltipColor(offSeries, "lightblue");


        const errorSeries = chart.series.push(new am4charts.StepLineSeries());
        errorSeries.name = "Fehler";
        errorSeries.dataFields.dateX = "date";
        errorSeries.dataFields.valueY = "errorValue";
        errorSeries.strokeWidth = 0;

        // Not showing any value here ... could be transformed tough
        errorSeries.tooltipText = "Fehler";
        errorSeries.fillOpacity = 0.2;
        errorSeries.fill = am4core.color("red");
        errorSeries.yAxis = currentAxis;
        errorSeries.adapter.add("tooltipText", OverviewPlot.ErrorTooltipAdapter);

        this.chart = chart;
    }

    private static ErrorTooltipAdapter(text: string | undefined, target: am4charts.LineSeries): string | undefined {

        // Doing smooth casting to figure out if the property is already there
        const data = target.tooltipDataItem.dataContext as { errorRegister: number };
        if (data && data.errorRegister > 0) {
            return ErrorHelper.ExplainErrorFlags(data.errorRegister).map(x => x.text).join(', \n');
        }

        return "";
    }

}
