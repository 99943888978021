import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import IPumpData from "../../interfaces/IPumpData";
import { PlotBase } from "..";
import IPumpPlotProps from "../../interfaces/IPumpPlotProps";

interface IDetailModel {
    date: Date;
    level: number;
    levelSetPoint: number;
    rpm: number;
    temperature: number;
    pressure: number;
    flow: number;
    vacuumTime: number;
}

// Using demo as described here
// https://www.amcharts.com/docs/v4/getting-started/integrations/using-react/
export class DetailPlot extends PlotBase<IDetailModel> {

    constructor(props: IPumpPlotProps) {
        super(props, 'detail-plot');
    }

    protected mapData(value: IPumpData): IDetailModel {
        return {
            date: new Date(value.timestamp),
            level: value.levelCurrent / 10,
            levelSetPoint: value.levelSetPoint / 10,
            rpm: value.rpm,
            temperature: value.temperature / 10,
            pressure: value.pressure,
            flow: value.flow > 0 ? value.flow / 1000 : 0,
            vacuumTime: value.vacuumTime
        };
    }

    protected axisHook(x: am4charts.Axis<am4charts.AxisRendererY>, isMobile: boolean): void {
        if (x.title.text !== 'Temperatur (°C)' && x.title.text !== 'Drehzahl (min-1)') {
            x.disabled = isMobile;
        }

        if (x.title.text === 'Temperatur (°C)') {
            x.renderer.opposite = !isMobile;
        }
    }

    protected createChart(): void {
        // declare chart
        const axisWidth = this.props.isMobile ? 0 : 35;
        const chart: am4charts.XYChart = am4core.create(
            this.key,
            am4charts.XYChart
        );

        // Adding a legend
        chart.legend = new am4charts.Legend();

        // Adding the cursor
        chart.cursor = new am4charts.XYCursor();

        // Adding default date axis
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.baseInterval = { timeUnit: "minute", count: 1 };

        // Axis (left)
        const fillLevelAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (fillLevelAxis.tooltip) {
            fillLevelAxis.tooltip.disabled = true;
        }
        fillLevelAxis.renderer.minWidth = axisWidth;
        fillLevelAxis.title.text = "Niveaus (cm)";

        // Axis (right, #1)
        const temperatureAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (temperatureAxis.tooltip) {
            temperatureAxis.tooltip.disabled = true;
        }
        temperatureAxis.renderer.minWidth = axisWidth;
        temperatureAxis.title.text = "Temperatur (°C)";
        temperatureAxis.renderer.opposite = true;
        temperatureAxis.min = 0;
        temperatureAxis.max = 50;

        // Axis (right, #2)
        const rpmAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (rpmAxis.tooltip) {
            rpmAxis.tooltip.disabled = true;
        }
        rpmAxis.renderer.minWidth = axisWidth;
        rpmAxis.title.text = "Drehzahl (min-1)";
        rpmAxis.renderer.opposite = true;
        rpmAxis.min = 0;
        rpmAxis.max = 1800;

        // Axis (right, #3)
        const pressureAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if (pressureAxis.tooltip) {
            pressureAxis.tooltip.disabled = true;
        }
        pressureAxis.renderer.minWidth = axisWidth;
        pressureAxis.title.text = "Druck (mbar)";
        pressureAxis.renderer.opposite = true;

        const vacuumTimeSeries = chart.series.push(new am4charts.ColumnSeries());
        vacuumTimeSeries.name = "Vakuumzeit";
        vacuumTimeSeries.dataFields.dateX = "date";
        vacuumTimeSeries.dataFields.valueY = "vacuumTime";
        vacuumTimeSeries.strokeWidth = 0;
        vacuumTimeSeries.fill = am4core.color("orange");
        vacuumTimeSeries.fillOpacity = 0.5;
        vacuumTimeSeries.tooltipText = "Zeit: {valueY} s";
        vacuumTimeSeries.yAxis = rpmAxis;

        // series
        const levelSeries = chart.series.push(new am4charts.LineSeries());
        levelSeries.name = "Niveau";
        levelSeries.dataFields.dateX = "date";
        levelSeries.dataFields.valueY = "level";
        levelSeries.strokeWidth = 2;
        levelSeries.tooltipText = "Niveau: {valueY} cm";
        levelSeries.yAxis = fillLevelAxis;
        this.setTooltipColor(levelSeries, "blue");

        const levelSetPointSeries = chart.series.push(new am4charts.LineSeries());
        levelSetPointSeries.name = "Niveau Sollwert";
        levelSetPointSeries.dataFields.dateX = "date";
        levelSetPointSeries.dataFields.valueY = "levelSetPoint";
        levelSetPointSeries.strokeWidth = 1.5;
        levelSetPointSeries.stroke = am4core.color("red");
        levelSetPointSeries.strokeDasharray = "3,3"
        levelSetPointSeries.tooltipText = "Sollwert: {valueY} cm";
        levelSetPointSeries.yAxis = fillLevelAxis;
        this.setTooltipColor(levelSetPointSeries, "red");

        const pressureSeries = chart.series.push(new am4charts.LineSeries());
        pressureSeries.name = "Druck";
        pressureSeries.dataFields.dateX = "date";
        pressureSeries.dataFields.valueY = "pressure";
        pressureSeries.stroke = am4core.color("green");
        pressureSeries.strokeWidth = 2;
        pressureSeries.tooltipText = "Druck: {valueY} mbar";
        pressureSeries.yAxis = pressureAxis;
        this.setTooltipColor(pressureSeries, "green");

        const rpmSeries = chart.series.push(new am4charts.LineSeries());
        rpmSeries.name = "Drehzahl";
        rpmSeries.dataFields.dateX = "date";
        rpmSeries.dataFields.valueY = "rpm";
        rpmSeries.stroke = am4core.color("#f54290");
        rpmSeries.strokeWidth = 2;
        rpmSeries.tooltipText = "Umdrehungen: {valueY} min-1";
        rpmSeries.yAxis = rpmAxis;
        this.setTooltipColor(rpmSeries, "#f54290");

        const temperatureSeries = chart.series.push(new am4charts.LineSeries());
        temperatureSeries.name = "Temperatur";
        temperatureSeries.dataFields.dateX = "date";
        temperatureSeries.dataFields.valueY = "temperature";
        temperatureSeries.strokeWidth = 2;
        temperatureSeries.tooltipText = "Temperatur: {valueY} °C";
        temperatureSeries.yAxis = temperatureAxis;

        this.chart = chart;
    }
}
